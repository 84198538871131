import * as PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';

import { capitalize } from '../helpers/utils';

import IndiaDetail from '../components/indiaDetail';
import OtherDestinations from '../components/OtherDestinations/OtherDestinations';
import Sidebar from '../components/Sidebar/sidebar';
import FlightCardsList from '../components/FlightCards/FlightCardsList';
import CallUs from '../components/CallUs/CallUs';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import Layout from '../components/layout';
import Trustpilot from '../components/Trustpilot';
import SubscribeForm from '../components/SubscribeForm';
import SEO from '../components/seo';
import { isFlykart } from '../helpers/urlParams';
import { callTrackingApiUrl } from '../configs';
import configurePageData from '../helpers/configurePageData';

class IndiaTemplate extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      indiaJson: PropTypes.object.isRequired,
      allMarkdownRemark: PropTypes.object,
      aImage: PropTypes.object,
      fImage: PropTypes.object
    })
  };

  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: props.phoneNumber,
      isBannersShown: false,
      isBannerApiResponseReceived: false,
      ipAction: '',
      configuredData: null
    };
  }

  async componentDidMount() {
    const dataConfig = await configurePageData(this.props.data.indiaJson.slug);
    const response = await fetch(`${callTrackingApiUrl}/call-tracking/banner`);
    const responseJson = await response.json();

    this.setState({
      isBannersShown: responseJson.data.show,
      isBannerApiResponseReceived: true,
      ipAction: responseJson.data.ipAction
    });

    if (dataConfig) {
      this.setState({
        configuredData: dataConfig
      });
    }
  }

  render() {
    const { indiaJson: indiaJSON, allMarkdownRemark } = this.props.data;
    const indiaJson = {
      ...indiaJSON,
      ...(this.state.configuredData && this.state.configuredData)
    };
    const {
      phoneNumber,
      isBannersShown,
      isBannerApiResponseReceived,
      ipAction
    } = this.state;
    const county = capitalize(indiaJson.title);
    const path = [...indiaJson.path, { name: county, slug: indiaJson.slug }];
    const destination = indiaJson.pairs[0]
      ? capitalize(indiaJson.pairs[0].destination)
      : '';
    const slug = indiaJson.slug;
    if (indiaJson.pairs) {
      indiaJson.pairs = indiaJson.pairs.sort(
        (pair1, pair2) => pair1.price - pair2.price
      );
    }
    const title = indiaJson.pairs[0]
      ? `Flights to ${county} (${indiaJson.pairs[0].destinationIATA}), ${destination} from $${indiaJson.pairs[0].price}`
      : `Find Cheap Flights to ${capitalize(county)}`;
    const description = indiaJson.pairs[0]
      ? `Find cheap flights to country from $${indiaJson.pairs[0].price}.
     Book online at travelopod.com or call us 24/7 for live booking assistance and last minute travel deals.`
      : `Find Cheap Flights to ${capitalize(
          county
        )}. Book online at travelopod.com or call us 24/7 for live booking assistance and last minute travel deals.`;

    const to = capitalize(indiaJson.title);
    const layoutTitle = `Find Cheap Flights to ${to}`;
    const flightCardsListTitle = `Cheap flights to ${to} from $${indiaJson.pairs[0].price}`;
    const flykartTheme = isFlykart();
    const img = flykartTheme
      ? this.props.data.fImage.childImageSharp.fluid
      : this.props.data.aImage.childImageSharp.fluid;
    return (
      <Layout
        pageData={indiaJSON}
        passPhoneNumber={phoneNumber => {
          this.setState({
            phoneNumber
          });
        }}
        title={layoutTitle}
        image={img}
        isBannersShown={isBannersShown}
        isBannerApiResponseReceived={isBannerApiResponseReceived}
        ipAction={ipAction}
      >
        <SEO title={title} description={description} slug={slug} />
        <div className="main">
          <Breadcrumbs path={path} />
          <div className="page-content">
            <div className="flight-content">
              <FlightCardsList
                city={indiaJson}
                title={flightCardsListTitle}
                cheapestPrice={
                  indiaJson.pairs[0] ? indiaJson.pairs[0].price : 0
                }
              />
              {isBannersShown && <CallUs phoneNumber={phoneNumber} />}
              <IndiaDetail
                country={indiaJson}
                htmlContent={
                  allMarkdownRemark.edges[0]
                    ? allMarkdownRemark.edges[0].node.html
                    : null
                }
              />
              {indiaJson.nearest && (
                <OtherDestinations
                  data={indiaJson.nearest}
                  text={`Flights to nearby countries on the ${capitalize(
                    indiaJson.title
                  )}`}
                />
              )}
            </div>
            <Sidebar destination={capitalize(indiaJson.title)} />
          </div>
          <SubscribeForm isInnerPage />
        </div>
        <Trustpilot />
      </Layout>
    );
  }
}

export default IndiaTemplate;

export const pageQuery = graphql`
  query($title: String!, $image: String) {
    indiaJson(title: { eq: $title }) {
      ...india_details
    }

    allMarkdownRemark(filter: { frontmatter: { page: { eq: $title } } }) {
      edges {
        node {
          html
          frontmatter {
            title
            date
          }
        }
      }
    }
    aImage: file(relativePath: { in: [$image, "flight-background.jpg"] }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    fImage: file(relativePath: { in: [$image, "flykart-img/flykart-bg.jpg"] }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
