import React from 'react';

import { graphql } from 'gatsby';

export default function IndiaDetail(props) {
  return (
    <div className="text-city">
      {props.htmlContent && (
        <div dangerouslySetInnerHTML={{ __html: props.htmlContent }} />
      )}
    </div>
  );
}

export const indiaDetailFragment = graphql`
  fragment india_details on IndiaJson {
    title
    path {
      name
      slug
    }
    tag {
      continent
      fixedNumber
    }
    slug
    pairs {
      slug
      title

      tags
      isSearchable
      appearanceOnDestination

      published
      thumbnail
      origin
      destination
      startDate
      endDate
      originIATA
      destinationIATA
      price
      airlineCode
      airlineName
      airlineLogo
    }
  }
`;
